<template>
  <div class="fullpage-container container-fluid">
    <div class="row pc-bg hidden-xs" :class="bg">

      <div class="container" style="height: 100%;">
        <div class="row" style="height: 100%;">
          <div class="col-sm-8 desc-box">
            <h3>{{ language.titles[currentIndex - 1] }}</h3>
            <p>{{ language.descs[currentIndex - 1] }}.</p>

            <div class="row">
              <div v-if="false" class="col-sm-4">
                <a class="ios" href="https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8">
                  <img src="/img/website/appStore.png" alt="">
                </a>
              </div>
              <div class="col-sm-4">
                <a class="android" href="https://play.google.com/store/apps/details?id=com.inlive.android">
                  <img src="/img/website/googlePlay.png" alt="">
                </a>
              </div>
              <div v-if="false" class="col-sm-4">
                <a class="apk" href="https://cdn.micoworld.net/apk/mico_release.apk">
                  <img src="//image.micoworld.net/44e11e348359f80dd4c023260d003216" alt="">
                </a>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div id="inner-container">
              <template v-for="image, index of language.images">
                <div class="section" :class="'section' + (index + 1)">
                  <img :src="image" />
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mobile-bg visible-xs-block">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <template v-for="($, index) of 5">
            <div class="swiper-slide" :key="$">
              <div :class="'bg' + $"></div>
              <div class="desc-box">
                <h3>{{ language.titles[index] }}</h3>
                <p>{{ language.descs[index] }}</p>
              </div>
              <div class="section section1">
                <img :src="language.images[index]" />
              </div>
            </div>
          </template>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="row mobile-dl visible-xs-block" style="padding-top: 20px">
      <div v-if="false" class="col-xs-4">
        <a class="ios" href="https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8">
          <img src="/img/website/appStore.png" alt="">
        </a>
      </div>
      <div class="col-xs-4 col-xs-offset-4">
        <a class="android" href="https://play.google.com/store/apps/details?id=com.inlive.android">
          <img src="/img/website/googlePlay.png" alt="">
        </a>
      </div>
      <div v-if="false" class="col-xs-4 col-xs-offset-2">
        <a class="apk" href="https://cdn.micoworld.net/apk/mico_release.apk">
          <img src="//image.micoworld.net/44e11e348359f80dd4c023260d003216" alt="">
        </a>
      </div>
      <div v-if="false" class="col-xs-12">
        <div style="margin-top: 10px; color: rgb(102, 102, 102); text-align: center;">
          Gmancal Studio.  © 2020<br>
          Address: Thiam Ruam Mit Rd, Huai Khwang, Bangkok 10310, Thailand
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import $ from 'jquery'
  import language from '../../common/language'
  export default {
    data() {
      return {
        currentIndex: 1,
        language: language.en.home
      }
    },
    computed: {
      bg() {
        return `bg${this.currentIndex}`
      }
    },
    mounted() {
      const _this = this
      $(() => {
        $("#inner-container").fullpage({
          'navigation': true,
          'continuousVertical': true,
          navigationPosition: 'right',
          afterLoad(_, index) {
            _this.currentIndex = index
          }
        })
        new Swiper('.swiper-container', {
          loop: true,
          pagination: {
            el: '.swiper-pagination',
          }
        })
      })
    }
  }
</script>
<style lang="less" scoped>
@import "../css/fullpage.less";
</style>
