var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "fullpage-container container-fluid" }, [
    _c("div", { staticClass: "row pc-bg hidden-xs", class: _vm.bg }, [
      _c("div", { staticClass: "container", staticStyle: { height: "100%" } }, [
        _c("div", { staticClass: "row", staticStyle: { height: "100%" } }, [
          _c("div", { staticClass: "col-sm-8 desc-box" }, [
            _c("h3", [
              _vm._v(_vm._s(_vm.language.titles[_vm.currentIndex - 1])),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(_vm._s(_vm.language.descs[_vm.currentIndex - 1]) + "."),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              false
                ? _c("div", { staticClass: "col-sm-4" }, [_vm._m(0)])
                : _vm._e(),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              false
                ? _c("div", { staticClass: "col-sm-4" }, [_vm._m(2)])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-4" }, [
            _c(
              "div",
              { attrs: { id: "inner-container" } },
              [
                _vm._l(_vm.language.images, function (image, index) {
                  return [
                    _c(
                      "div",
                      {
                        staticClass: "section",
                        class: "section" + (index + 1),
                      },
                      [_c("img", { attrs: { src: image } })]
                    ),
                  ]
                }),
              ],
              2
            ),
          ]),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mobile-bg visible-xs-block" }, [
      _c("div", { staticClass: "swiper-container" }, [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          [
            _vm._l(5, function ($, index) {
              return [
                _c("div", { key: $, staticClass: "swiper-slide" }, [
                  _c("div", { class: "bg" + $ }),
                  _vm._v(" "),
                  _c("div", { staticClass: "desc-box" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.language.titles[index]))]),
                    _vm._v(" "),
                    _c("p", [_vm._v(_vm._s(_vm.language.descs[index]))]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "section section1" }, [
                    _c("img", { attrs: { src: _vm.language.images[index] } }),
                  ]),
                ]),
              ]
            }),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "swiper-pagination" }),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "row mobile-dl visible-xs-block",
        staticStyle: { "padding-top": "20px" },
      },
      [
        false ? _c("div", { staticClass: "col-xs-4" }, [_vm._m(3)]) : _vm._e(),
        _vm._v(" "),
        _vm._m(4),
        _vm._v(" "),
        false
          ? _c("div", { staticClass: "col-xs-4 col-xs-offset-2" }, [_vm._m(5)])
          : _vm._e(),
        _vm._v(" "),
        false ? _c("div", { staticClass: "col-xs-12" }, [_vm._m(6)]) : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "ios",
        attrs: {
          href: "https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8",
        },
      },
      [_c("img", { attrs: { src: "/img/website/appStore.png", alt: "" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-sm-4" }, [
      _c(
        "a",
        {
          staticClass: "android",
          attrs: {
            href: "https://play.google.com/store/apps/details?id=com.inlive.android",
          },
        },
        [_c("img", { attrs: { src: "/img/website/googlePlay.png", alt: "" } })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "apk",
        attrs: { href: "https://cdn.micoworld.net/apk/mico_release.apk" },
      },
      [
        _c("img", {
          attrs: {
            src: "//image.micoworld.net/44e11e348359f80dd4c023260d003216",
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "ios",
        attrs: {
          href: "https://itunes.apple.com/us/app/mico-meet-new-people-chat/id908023218?mt=8",
        },
      },
      [_c("img", { attrs: { src: "/img/website/appStore.png", alt: "" } })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-xs-4 col-xs-offset-4" }, [
      _c(
        "a",
        {
          staticClass: "android",
          attrs: {
            href: "https://play.google.com/store/apps/details?id=com.inlive.android",
          },
        },
        [_c("img", { attrs: { src: "/img/website/googlePlay.png", alt: "" } })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "apk",
        attrs: { href: "https://cdn.micoworld.net/apk/mico_release.apk" },
      },
      [
        _c("img", {
          attrs: {
            src: "//image.micoworld.net/44e11e348359f80dd4c023260d003216",
            alt: "",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticStyle: {
          "margin-top": "10px",
          color: "rgb(102, 102, 102)",
          "text-align": "center",
        },
      },
      [
        _vm._v("\n        Gmancal Studio.  © 2020"),
        _c("br"),
        _vm._v(
          "\n        Address: Thiam Ruam Mit Rd, Huai Khwang, Bangkok 10310, Thailand\n      "
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }