/**
 * Created by Sam on 2020/6/12.
 */

export default {
  en: {
    home: {
      titles: [
        'LIVE',
        'PK SHOW',
        'PARTY CHAT',
        'LUDO',
        'SOCIAL',
        // "SEE WHO'S NEARBY",
        // "SHORT VIDEOS",
        // "LIVE STREAMING",
        // "GROUP",
        // "FREE CHAT"
      ],
      descs: [
        'Content is Very Exciting',
        'Let\'s See Who is More Exciting',
        'Up To 9 People Video',
        'Wonderful Ludo Battle',
        'Meet More Friends Here',
        // "Meet interesting people nearby.",
        // "Find out the funniest and the coolest short videos!",
        // "Watch and broadcast anytime and anywhere.",
        // "Find a bunch of nearby people with the same interests.",
        // "Chat with someone nearby!"
      ],
      images: [
        '/img/website/dodo/LIVE.png',
        '/img/website/dodo/PK-SHOW.png',
        '/img/website/dodo/PARTY-CHAT.png',
        '/img/website/dodo/LUDO.png',
        '/img/website/dodo/SOCIAL.png',
        // '//image.micoworld.net/5a646d69d39b6ed9894d4173f914bede',
        // '//image.micoworld.net/02da232e9c30cf0358739d4708d09365',
        // '//image.micoworld.net/32a57f1a2e562ccd69c4ac705bb61571',
        // '//image.micoworld.net/ff4e871fcfff630b2061b7ef03e1f0ff',
        // '//image.micoworld.net/5bd432ca4e031d57a56dc365d8ba618e'
      ]
    }
  }
}
